.container {
  position: relative;

  .button {
    float: right;
  }

  .chart {
    position: relative;
  }

  .labelAxisX {
    text-align: center;
    font-size: 0.85em;
  }
}