$font-family: "DM Sans", sans-serif;

// colors
$alice-blue: #f2f8fc;
$bright-gray: #eeeeee;
$bright-gray-2: #ebebeb;
$black: #000000;
$blue: #0000ff;
$blue-50: #0000ff50;
$blueberry: #5583fb;
$blue-cola: #008ede;
$blue-violet: #7264bb;
$blue-ryb: #0055ff;
$bubbles: #e3f8ff;
$cetacean-blue: #041238;
$cornflower-blue: #649dff;
$cosmic-latte: #fffbe6;
$chinese-white: #e0e0e0;
$chinese-silver: #c8c8c8;
$chinese-silver-c: #cccccc;
$cultured: #c9c0c0;
$dark-charcoal-3: #333333;
$dark-charcoal-80: #33333380;
$dark-gray-x11: #aaaaaa;
$dark-orange: #ff9000;
$dark-tangerine: #f79e13;
$davys-grey: #585857;
$davys-grey-5: #555555;
$deep-puce: #a85f66;
$forest-green: #4f9b73;
$granite-gray: #666666;
$granite-gray-2: #656565;
$gray: #7c7c7c;
$gray-x11: #bbbbbb;
$han-blue: #5074d1;
$lavander-blue: #c8d9f7;
$lavander-web: #e5ecfb;
$lotion: #fbfbfb;
$magic-mint: #B5E8C7;
$majorelle-blue: #5a5adf;
$maximun-blue-purple: #a8b7ec;
$medium-purple: #8975de;
$nickel: #737373;
$ochre: #cd8613;
$outer-space: #444444;
$platinum: #e2e2e2;
$platinum-e7: #e7e7e7;
$persimmon: #e04d00;
$pineapple: #594214;
$raising-black: #242424;
$rich-black: #030927;
$safety-orange: #ff6b00;
$silver-chalice: #adadad;
$silver-foil: #b0b0b0;
$sonic-silver: #777777;
$space-cadet: #131b55;
$spanish-gray: #969696;
$ue-red: #b10000;
$urobilin: #e0b927;
$violet-blue: #3636b8;
$violet-blue-light: #3653b8;
$white: #ffffff;
$yellow-green: #aaea7d;
$zinnwaldite-brown: #2b2111;

// app colors
$active-status-color: #52c41a;
$body-background-dark: #030829;
$border-color: #313131;
$btn-bg-hover: #1a1a51;
$btn-bg-hover-light: #e4e8f5;
$content-background: #1f2940;
$disabled-color: #c7c7c7;
$divider-color: #d6d6d6;
$dropdown-border-color: $violet-blue-light;
$dropdown-border-color-light: $maximun-blue-purple;
$even-row-background-dark: #041238;
$inactive-status-color: grey;
$odd-row-background-dark: $body-background-dark;
$pagination-background-dark: $cetacean-blue;
$pagination-border-dark: #151285;
$primary-color-dark: blue;
$primary-color-hover-dark: #696ffb;

$row-selected-dark: #19284f;

$submenu-border-color: $outer-space;
$table-thead: #565656;
$table-title: #919191;
$title-color-dark: #dedede;
$top-bar-icon: $dark-charcoal-3;
$top-bar-background: #e7bf34;

// light app colors
$border-color-light: $body-background-dark;
$background-light: $white;
$body-background-light: $white;
$title-color-light: $dark-charcoal-3;
$dropdown-color-light: $sonic-silver;
$dropdown-hover-color-light: $lavander-web;
$row-selected-light: $lavander-web;
$even-row-background-light: $alice-blue;
$odd-row-background-light: $body-background-light;
$pagination-background-light: $white;
$pagination-border-light: $davys-grey-5;
$primary-color-light: #1b62e6;
$primary-color-hover-light: #5583fb;

// colors with opacity
$light-silver-20: #d9d9d920;
$eucalyptus-11: #3affac1c;
$cornflower-blue-12: #5c95ff1f;
$strawberry-15: #ff568926;
$white-40: #ffffff40;
$white-80: #ffffff80;
$white-bf: #ffffffbf;
$rich-black-80: #03082980;
$chinese-silver-80: #c7c7c740;
$violet-blue: #3653b880;

$shandy: #f4e56e;
$raisin-black: #1f2325;
$light-gray: #d3d3d3;
$state-blue: #646cd0;
$quick-silver: #a7a7a7;
$yankees-blue: #1d2337;
$orange-red: #ff4d4f;
$rich-black: #090c15;
$green-crayola: #17c776;
$salmon: #ff7979;
$indigo: #25365f;
$dim-gray: #6b6b6b;
$davis-grey: #5d5d5d;
$gainsboro: #dddddd;
$jet: #353535;
$dark-cornflower-blue: #1f4397;
$dark-gunmetal: #141b2d;
$deep-space-sparkle: #4d616f;
$anti-flash-white: #f0f0f0;
$persimmon: #e04d00;
$cadmium-green: #00663c;
$french-wine: #af1242;
$ghost-white: #f8f9f9;
$argent: #bfbfbf;
$bdazzled-blue: #325c8c;
$coral-reef: #ff7875;
$light-silver: #d9d9d9;
$dark-charcoal: #303335;
$philippine-gray: #909090;

// Sidebar
$sider-background-dark: #050a30;
$sider-border-dark: #14126b;
$submenu-border-color-dark: $outer-space;
$menu-arrow-color-dark: $gainsboro;
$item-selected-dark: $primary-color-dark;
$title-item-dark: $gainsboro;
$title-item-hover-dark: $gainsboro;

$sider-background-light: $lotion;
$sider-border-light: $bright-gray-2;
$submenu-border-color-light: $outer-space;
$menu-arrow-color-light: $dark-charcoal-3;
$menu-arrow-color-hover-light: $white;
$item-selected-light: $primary-color-light;
$title-item-light: $dark-charcoal-3;
$title-item-hover-light: $white;

$denim-blue: #1242b0;
$true-blue: #065ed9;
$denim: #0c5ccc;
$green-screamin: #45f441;

$mandy: #ec4c69;
$may-green: #43A047;
$bleu-de-france: #1E88E5;
