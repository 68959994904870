@use '../../../common/styleConstants.scss' as *;

.container {
  .value {
    color: $black;
    display: flex;
  }

  .label {
    color: #747474;
    font-size: 13px;
  }
}