
.commercialSizesByAnimal {
  user-select: none;
  margin-bottom: 6px;

  .title {
    font-size: 14px;
  }

  .description {
    font-size: 13px;
  }
}