
.react-calendar-heatmap .color-empty {
  fill: #ebedf0;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #c6e48b;
}

.react-calendar-heatmap .color-scale-2 {
  fill: #7bc96f;
}

.react-calendar-heatmap .color-scale-3 {
  fill: #239a3b;
}

.react-calendar-heatmap .color-scale-4 {
  fill: #196127;
}

.react-calendar-heatmap .react-calendar-heatmap-month-label {
  font-size: 8px;
}

.react-calendar-heatmap .react-calendar-heatmap-weekday-label {
  font-size: 8px;
}

.react-calendar-heatmap .weekday-label {
  visibility: visible !important; /* Asegura que todas las etiquetas sean visibles */
  font-size: 12px; /* Ajusta el tamaño */
}