@use '../../../common/styleConstants.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button {
    float: right;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .description {
      width: fit-content;
      padding: 2px 10px;
      border-radius: 6px;
      background-color: $magic-mint;
      font-size: 14px;
    }
  
    .belowDescription {
      background-color: $yellow-green;
    }
  }

  .incomes {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 0 4px 0;

    span {
      font-size: 14px;
    }
  }
}