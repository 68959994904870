@use '../../../common/styleConstants.scss' as *;

.container {
  position: relative;
  
  .header {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 3px 8px 0 8px;

    .title {
      font-size: 13px;
    }
  
    .iconContainer {
      display: flex;
      justify-content: center;
      cursor: pointer;
      
      background-color: $white-bf;
      border-radius: 16px;
      width: 26px;
      height: 26px;

      visibility: hidden;
      opacity: 0;
      transform: translateY(-4px);
      transition: 0.3s;

      .icon {
        font-size: 18px;
      }
    }
  }

  .chart {
    background-color: $lotion;
    border-radius: 6px;
  }

  &:hover {
    .header {
      .iconContainer { 
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: 0.3s;
      }
    }
  }
}
