.progressContainer {
  width: 100%;
  border-radius: 5px;
  height: 18px;
}

.progressBar {
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: width 0.3s ease-in-out;
}
