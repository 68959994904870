@use '../../../common/styleConstants.scss' as *;

.container {
  margin: 12px 24px 24px 24px;
  display: flex;
  justify-content: space-between;

  .options {
    display: flex;
    align-items: center;

    .detailHarvest {
      border-radius: 6px;
      padding: 0 10px;
      border: 1px solid $chinese-silver;
      cursor: pointer;

      &:hover {
        background-color: $lotion;
      }
      
      .icon {
        font-size: 20px;
      }
    }
  }
}